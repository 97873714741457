import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';

// Amplify
import { Amplify } from 'aws-amplify';

// Config
import config from './config';

// Theme
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";


// Translate
import './i18n';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "demo",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3C7FBA"
    },
  }
});

ReactDOM.render(
  <Suspense fallback={(<div></div>)}>
    <Router>
      <React.StrictMode>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </React.StrictMode>
    </Router>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
