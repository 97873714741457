import React, { Fragment } from 'react';
// Material ui
import {
    Container,
    Grid,
    Typography,
}
    from '@material-ui/core/';

// Material ui styles
import { makeStyles } from '@material-ui/core/styles';

import Moment from 'react-moment';

import {
    isMobile
} from "react-device-detect";


const useStyles = makeStyles((theme) => ({
    Badge: {
        position: 'absolute',
        right: 300,
    },
    BadgeMobile: {
        position: 'absolute',
        right: 10,
    },
    GreenBox: {
        padding: '4px 0px 4px 5px',
        marginLeft: '4px',
        marginRight: '3px',
        backgroundColor: 'rgba(96,217,55,0.48)'
    },

}));

const CurrentDate = ({ date }) => {

    const classes = useStyles();

    return (
        <Fragment>
            <a href="https://www.producthunt.com/posts/daily-domain-ideas?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-daily-domain-ideas" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=300251&theme=light" alt="Daily Domain Ideas 💡 - Daily product ideas with expired .com domains 💡 | Product Hunt"
                className={isMobile ? classes.BadgeMobile : classes.Badge}
            /></a>
            <Typography variant="body1">
                <Moment format="DD MMMM YYYY">
                    {date}
                </Moment>
            </Typography>

            <Typography variant="h4">
                <Moment format="dddd">
                    {date}
                </Moment>
            </Typography>

            <Typography variant="h6">Daily tips on<span className={classes.GreenBox}>available </span> .com domains - generated by AI</Typography>

        </Fragment>
    );

};
export default CurrentDate;