import React, { Fragment, useEffect, useState } from 'react';

import { API } from "aws-amplify";
import './App.css';

import { useLocation } from "react-router-dom";

import Routes from "./Routes";


// Components
import ButtonGrid from './ButtonGrid';
import CurrentDate from './CurrentDate';
import DomainGrid from './DomainGrid';
import Subscribe from './Subscribe';

import jsonp from "jsonp";
import toQueryString from "to-querystring";

// Hooks
import { onError } from "./libs/errorLib";
import { useSnackbar } from './libs/useSnackbar';
import analytics from './analytics'

// Logo
import logo from './logo_loader.gif';

// Material ui
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';


function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    analytics.page({
      url: location.pathname
    });
    analytics.track("new visitor");
  }, [location])
}

function App() {


  // Location
  let location = useLocation();
  usePageViews()


  return (
    <div>
      <Routes />
    </div>

  );
}

export default App;
