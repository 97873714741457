import React, { useCallback, useState } from 'react';

import classes from './DomainGrid.module.css';

// Material ui
import {
    Button,
    Chip,
    Divider,
    Grid,
    TextField,
    Typography,
}
    from '@material-ui/core/';

// Material ui icpns
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import SendIcon from '@material-ui/icons/Send';
// Material ui styles
import { makeStyles } from '@material-ui/core/styles';

import {
    isMobile
} from "react-device-detect";

import Parser from 'html-react-parser';

// Scroll
import { Link } from "react-scroll";

// Fit text
import useFitText from "use-fit-text";

import { Textfit } from 'react-textfit';




const useStyles = makeStyles((theme) => ({
    ArrowDown: {
        transform: "rotate(90deg)"
    },
    BackgroundColor: {
        backgroundColor: 'white',
        borderRadius: '5px',
    },
    Button: {
        backgroundColor: '#FDE12D',
        height: '40px',
    },
    ButtonGrid: {
        marginTop: '30px'
    },
    CenterGrid: {
        textAlign: 'center',
    },
    ChipFalse: {
        backgroundColor: '#ff9e80',
        borderRadius: '3px',
        height: '24px',
        marginBottom: '9px'
    },
    ChipTrue: {
        backgroundColor: '#b9f6ca',
        borderRadius: '3px',
        height: '24px',
        marginBottom: '9px'
    },
    Divider: {
        margin: '10px 0 23px 0px',
        width: '15%'
    },
    GreenBox: {
        padding: '4px 0px 4px 6px',
        marginRight: '2px',
        backgroundColor: 'rgba(96,217,55,0.48)'
    },
    GridTop: {
        paddingTop: '50px',
        paddingBottom: '50px',
    },
    GridTopMobile: {
        paddingTop: '50px',
        paddingBottom: '0px',
    },
    MobileTypography: {
        fontSize: '2em',
    },
    SubscriptionError: {
        color: 'red',
        display: 'block'
    },
}));



const DomainGrid = ({ analyze, domain, font, idea, image, isSending, status, subscribe, subscriptionMessage, subscriptionStatus, taken }) => {

    const [email, setEmail] = useState("");

    const onStart = useCallback(() => {
    }, []);
    const onFinish = useCallback((fontSize) => {
    }, []);

    const { fontSize, ref } = useFitText({ maxFontSize: 600, onStart, onFinish });

    // const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container className={isMobile ? classes.GridTopMobile : classes.GridTop} spacing={2}>
                    {/* {isMobile && <Grid item xs={12} className={classes.CenterGrid}>
                        <img src={image} className={classes.MobileImage} />
                    </Grid>} */}

                    <Grid item xs={12} md={8}

                    // style={{backgroundColor: 'pink'}}
                    >
                        <Chip size="small" label={status === 'True' ? "Still available" : `Got taken ${taken}`} className={status === 'True' ? classes.ChipTrue : classes.ChipFalse} />
                        {/* <div ref={ref}
                            style={{
                                fontSize,
                                width: "100%",
                            }}>
                            <p className={classes[font]}>
                                {domain}
                            </p>

                        </div> */}
                        <div className={classes[font]}>
                            <Textfit mode="single" className={classes.Fix}>

                                {domain}
                            </Textfit>
                        </div>

                        <Grid item xs={12} md={8}>
                            <Divider className={classes.Divider} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6">
                                <span className={classes.GreenBox}>Idea: </span> {idea}
                                {/* {`Idea: ${idea}`} */}
                            </Typography>
                        </Grid>
                        {subscriptionStatus ?
                            <Typography variant="body2">Thank you for subscribing to a new domain idea, check your email for your first idea.</Typography>

                            :
                            <Grid container item xs={12} spacing={1} md={8} className={classes.ButtonGrid}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        autoComplete="email"
                                        className={classes.BackgroundColor}
                                        onFocus={() => analyze('email_input', 'weekly', 'email_input')}
                                        onChange={(event) => setEmail(event.target.value)}
                                        fullWidth
                                        size="small"
                                        id="outlined-basic"
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        id="email"
                                        name="email"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* <Link
                                    color="inherit"
                                    to="subscribe"
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={700}
                                > */}
                                    <Button disabled={isSending} onClick={() => (
                                        subscribe(email),
                                        analyze('subscribe_button', 'weekly', 'subscribe')
                                    )} fullWidth variant="contained" disableElevation className={classes.Button}>Get 3 ideas weekly
                                        {/* <SendIcon fontSize="small" /> */}
                                    </Button>
                                    {/* </Link> */}

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    {subscriptionMessage && <Typography variant="caption" className={classes.SubscriptionError}>{Parser(subscriptionMessage)}</Typography>}
                                    <Typography variant="caption">Join our free weekly newsletter and receive 3 domain ideas every week.</Typography>
                                    <br />
                                    <Typography variant="caption">Here's the latest issue: <a href="https://mailchi.mp/f3c02a4bcd81/weekly-domain-ideas-006" target="_blank">Weekly Domain Ideas #006</a></Typography>
                                </Grid>
                            </Grid>}
                        {/* <Grid container item xs={12} md={8} className={classes.ButtonGrid}>
                            <Button variant="contained" disableElevation className={classes.Button}>Subscribe to 5 new ideas weekly <ArrowRightAltIcon className={classes.ArrowDown} /></Button>
                        </Grid> */}
                    </Grid>
                    {/* {!isMobile &&
                        <Grid item xs={4}
                        // style={{backgroundColor: 'blue'}}
                        >
                            <img src={image} style={{ transform: 'scaleX(-1)' }} />
                        </Grid>} */}
                </Grid>

            </Grid>
        </Grid>
    );

};
export default DomainGrid;