import React, { Fragment } from 'react';
// Material ui
import {
    ButtonBase,
    Container,
    Grid,
    Typography,
}
    from '@material-ui/core/';

// Material ui icons
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TwitterIcon from '@material-ui/icons/Twitter';

// Material ui styles
import { makeStyles } from '@material-ui/core/styles';


// Scroll
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
    ArrowLeft: {
        marginBottom: '-7px',
        transform: "rotate(-180deg)"
    },
    ArrowRight: {
        marginBottom: '-7px',
    },
    ArrowUp: {
        marginBottom: '-7px',
        transform: "rotate(-90deg)"

    },
    GridTop: {
        paddingTop: '0px',
        paddingBottom: '50px',
    },
    RightGrid: {
        textAlign: 'right'
    },
    Twitter: {
        height: '20px',
        marginBottom: '-4px',
        marginRight: '-4px',
        color: '#1DA1F2'
    },
}));

const ButtonGrid = ({ analyze, chosenDay, domains, setChosenDay }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Grid container className={classes.GridTop} spacing={2}>
                <Grid item xs={6} md={4}>

                    {chosenDay !== domains.length - 1

                        ?

                        <ButtonBase className={classes.ArrowText} onClick={() => chosenDay !== domains.length - 1 && (
                            setChosenDay(chosenDay + 1),
                            analyze('prev_day', chosenDay + 1, 'day_index')
                        )}>

                            <Typography variant="caption">
                                <ArrowRightAltIcon className={classes.ArrowLeft} /> Previous day
                  </Typography>

                            {/* <Link
                            color="inherit"
                            to="subscribe"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                        >

                        </Link> */}

                        </ButtonBase>

                        :
                        <Typography variant="caption">
                            Subscribe for weekly domain ideas <ArrowRightAltIcon className={classes.ArrowUp} />
                        </Typography>
                    }

                </Grid>
                {chosenDay !== 0 && <Grid item xs={6} md={3} className={classes.RightGrid}>
                    <ButtonBase className={classes.ArrowText} onClick={() => (
                        setChosenDay(chosenDay - 1),
                        analyze('next_day', chosenDay + 1, 'day_index')
                    )}>
                        <Typography variant="caption">
                            Show next day <ArrowRightAltIcon className={classes.ArrowRight} />
                        </Typography>
                    </ButtonBase>
                </Grid>}
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="caption">
                        Follow us for daily domain ideas on <TwitterIcon className={classes.Twitter} /> <a target="_blank" onClick={()=>analyze('twitter_link', "https://twitter.com/DomainIdeaDaily", 'twitter_link')} href="https://twitter.com/DomainIdeaDaily">@DomainIdeaDaily</a>
                    </Typography>
                </Grid>
            </Grid>
        </Fragment>

    );

};
export default ButtonGrid;