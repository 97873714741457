import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Feedback from "./Feedback";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/feedback/:id">
        <Feedback />
      </Route>
    </Switch>
  );
}