import React, { Fragment, useEffect, useState } from 'react';

import { API } from "aws-amplify";
import './App.css';

import { useLocation } from "react-router-dom";


// Components
import ButtonGrid from './ButtonGrid';
import CurrentDate from './CurrentDate';
import DomainGrid from './DomainGrid';
import Subscribe from './Subscribe';

import jsonp from "jsonp";
import toQueryString from "to-querystring";

// Hooks
import { onError } from "./libs/errorLib";
import { useSnackbar } from './libs/useSnackbar';
import analytics from './analytics'

// Logo
import logo from './logo_loader.gif';

// Material ui
import {
    Avatar,
    Button,
    Chip,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// Material ui icons
import ClearIcon from '@material-ui/icons/Clear';


// Moment
import Moment from 'react-moment';
import 'moment';
import 'moment-timezone';


var moment = require('moment');

const useStyles = makeStyles((theme) => ({
    GridTop: {
        paddingTop: '80px',
        // paddingBottom: '80px'
    },
    IconChip: {
        marginRight: '5px',
        marginTop: '8px'
    },
    Img: {
        height: '50px',
        display: 'block',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    Loader: {
        marginTop: '150px',
        width: '100%',
    },
    Paper: {
        border: 'solid rgba(0, 0, 0, 0.08) 1px',
        boxShadow: 'none',
        padding: '25px',
        borderRadius: '10px',
    },
    ParentDiv: {
        paddingBottom: '60px'
    },
    bg: {
        /* The image used */
        backgroundImage: 'url("https://di-public-files-a2m2x.s3.amazonaws.com/test_bg.png")',

        /* Full height */
        height: '100%',

        /* Center and scale the image nicely */
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    backgroundImage: {
        backgroundImage: `url("https://di-public-files-a2m2x.s3.amazonaws.com/test_bg.png")`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        minHeight: "100%",
        height: "100vh",
        position: "relative",
        paddingBottom: '80px'
    }
}));

function usePageViews() {
    const location = useLocation();
    useEffect(() => {
        analytics.page({
            url: location.pathname
        })
    }, [location])
}



export default function Home() {

    const classes = useStyles();

    const [categories, setCategories] = useState([]);

    // Chosen day
    const [chosenDay, setChosenDay] = useState(0);

    // Domains
    const [domains, setDomains] = useState();

    // Loading
    const [isSending, setIsSending] = useState(false);

    // Location
    let location = useLocation();
    usePageViews()

    // Subscription
    const [subscriptionMessage, setSubscriptionMessage] = useState("");
    const [subscriptionStatus, setSubscriptionStatus] = useState(false);


    async function subscribe(email) {
        setIsSending(true)

        let temp = {
            u: "d6e53bdd57716166b36edca29",
            "amp;id": "1aa17ae1ad",
            EMAIL: email,
        }

        const params = toQueryString(temp);
        const url = 'https://gmail.us1.list-manage.com/subscribe/post-json?' + "&" + params;


        try {
            jsonp(
                url,
                {
                    param: "c"
                },
                (err, data) => {
                    if (err) {
                        setSubscriptionMessage(err)
                    } else if (data.result !== "success") {
                        setSubscriptionMessage(data.msg)
                    } else {
                        setSubscriptionMessage(data.msg)
                        setSubscriptionStatus(true)

                    }
                }
            )

        } catch (e) {
            console.log("e: ", e)

        }

        setIsSending(false)

    }

    useEffect(() => {
        // Fetch domains
        async function onLoad() {

            const loadItems = (dbInit) => {
                return API.get("demo", `/list_domains`, dbInit);
            }

            let today = moment(new Date()).format('YYYY-MM-DD')
            try {

                let fetch = await loadItems({
                    queryStringParameters: {
                        date: today,
                    }
                })
                setDomains(fetch['status'])

            } catch (e) {

                console.log("Error: ", e)

            }
        }
        onLoad();
    }, [])

    const chosenCategories = categories.map((option, index) => {
        // This is to handle new options added by the user (allowed by freeSolo prop).
        const label = option.title || option;
        return (
            <Chip
                avatar={<Avatar alt="Natacha" src={option.image} />}
                className={classes.IconChip}
                key={label}
                label={label}
                deleteIcon={<ClearIcon />}
                variant="outlined"
                onDelete={() => {
                    setCategories(categories.filter(entry => entry !== option));
                }}
            />
        );
    });

    function analyze(name, value, key) {
        analytics.track(name, {
            [key]: value,
            url: location.pathname
        })
    }

    return (
        <Fragment>
            {domains ?
                <div className={classes.backgroundImage} style={{ backgroundImage: `url(${domains[chosenDay].image})` }}>
                    <Container maxWidth="md" className={classes.GridTop}>
                        <Fragment>
                            <CurrentDate
                                date={domains[chosenDay].gsi1.split('#DATE#')[1]}
                            />
                            <DomainGrid
                                analyze={(popularity, value, score) => analyze(popularity, value, score)}
                                domain={domains[chosenDay].domainName}
                                font={domains[chosenDay].fontClass}
                                image={domains[chosenDay].image}
                                idea={domains[chosenDay].idea}
                                isSending={isSending}
                                status={domains[chosenDay].gsi1.split('#')[1]}
                                subscribe={(data) => subscribe(data)}
                                subscriptionMessage={subscriptionMessage}
                                subscriptionStatus={subscriptionStatus}
                                taken={'taken' in domains[chosenDay] ? domains[chosenDay].taken : null}
                            />
                            <ButtonGrid
                                analyze={(popularity, value, score) => analyze(popularity, value, score)}
                                chosenDay={chosenDay}
                                domains={domains}
                                setChosenDay={(data) => setChosenDay(data)}
                            />
                            {/* <Grid container spacing={2}>
                                <Grid item xs={6} md={4}>
                                    <a href="https://www.producthunt.com/posts/daily-domain-ideas?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-daily-domain-ideas" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=300251&theme=light" alt="Daily Domain Ideas 💡 - Daily product ideas with expired .com domains 💡 | Product Hunt"
                                        // style={{ width: "250px", height: "54px" }}
                                        // width="250" height="54" 
                                        /></a>
                                </Grid>
                            </Grid> */}
                        </Fragment>

                    </Container>
                </div>
                :
                <Container maxWidth="md" className={classes.GridTop}>
                    <Grid container justify="center">
                        {/* <CircularProgress size={70} /> */}
                        <img src={logo} className={classes.Loader} />
                        <Typography variant="caption">
                            Loading your daily domain idea
              </Typography>
                    </Grid>
                </Container>
            }
        </Fragment>
    )


}