import React, { useEffect, useState } from 'react';
// Material ui
import {
    Button,
    Container,
    Grid,
    Paper,
    Typography,
}
    from '@material-ui/core/';

    import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

import analytics from './analytics'

import { makeStyles } from '@material-ui/core/styles';

import { useHistory, useLocation } from "react-router-dom";

import thumb from './thumb.gif'

const useStyles = makeStyles((theme) => ({
    Background: {
        backgroundColor: '#ecf0f1',
        minHeight: '100vh',
    },
    GridTop: {
        paddingTop: '80px',
        paddingBottom: '80px'
    },
    Icon: {
        transform: "rotate(-180deg) scaleX(-1)",
    },
    IconGrid: {
        textAlign: 'center',
    },
    Paper: {
        border: 'solid rgba(0, 0, 0, 0.08) 1px',
        boxShadow: 'none',
        padding: '45px 35px 45px 35px',
        borderRadius: '10px',
    },
    TypographyGrid: {
        marginTop: '20px'
    }
}));

export default function Feedback() {
    const classes = useStyles();

    const [feedback, setFeedback] = useState();

    // History
    const history = useHistory();

    let location = useLocation();
    useEffect(() => {
        let route = location.pathname.split('/').slice(-1)[0]
        setFeedback(route.slice(-1))
        let uuid = route.slice(0, 20)
        let nwsletter = route.slice(20, 23)
        let nr = route.slice(23, 24)
        let vote = route.slice(-1)

        analytics.track("Newsletter", {
            uuid: uuid,
            newsletter: nwsletter,
            domain: nr,
            feedback: vote,
            url: location.pathname
        })

    }, [location])
    return (
        <div className={classes.Background}>
            <Container maxWidth="sm" className={classes.GridTop}>

                <Paper className={classes.Paper}>
                    <Grid container className={classes.IconGrid}>
                        <Grid item xs={12} >
                            <img src={feedback % 2 == 0 ? thumb : thumb} className={feedback % 2 != 0 ? null : classes.Icon} height={100} />
                        </Grid>
                        <Grid item xs={12} className={classes.TypographyGrid}>
                            <Typography variant="h5">Thank you for your feedback!</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.TypographyGrid}>
                            <Button onClick={() => history.push('/')} disableElevation color="primary" variant="contained">Back to daily domain ideas <EmojiObjectsIcon /></Button>
                        </Grid>

                    </Grid>
                </Paper>

            </Container>
        </div>
    );

};
