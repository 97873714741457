import React from 'react';
// Material ui
import {
    Button,
    Chip,
    Container,
    Grid,
    Paper,
    TextField,
    Typography,
}
    from '@material-ui/core/';

// Material ui icons
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';





import { Autocomplete } from '@material-ui/lab/';

// Material ui styles
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    Arrow: {
        marginLeft: '10px'
    },
    Button: {
        backgroundColor: '#FDE12D',
    },
    ButtonGrid: {
        marginTop: '30px'
    },
    ButtonSignup: {
        backgroundColor: '#FF6663',
    },
    Chip:{
        backgroundColor: '#FDE12D',
        // height: '20px',
    },
    Paper: {
        flexGrow: 1,
        border: 'solid rgba(0, 0, 0, 0.08) 1px',
        boxShadow: 'none',
        padding: '25px',
        borderRadius: '10px',
    },
    ListIcon: {
        height: '20px',
        marginBottom: '-5px',
        paddingRight: '5px'
    },
}));

const Subscribe = ({ chosenCategories, categories, options, setCategories }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.Paper} name="subscribe">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6">Subscribe to daily or weekly domain ideas <Chip className={classes.Chip} size="small" label="PRO" /></Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        freeSolo
                        getOptionDisabled={(options) => (categories.length === 1 ? true : false)}
                        filterSelectedOptions
                        defaultValue={[]}
                        options={options}
                        onChange={(e, newValue) => setCategories(newValue)}
                        getOptionLabel={(option) => option.title}
                        renderTags={() => { }}
                        value={categories}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                helperText="Leave blank to receive various categories"
                                label="Choose up to 1 category to subscribe to"
                            />
                        )}
                        renderOption={(option) => (
                            <div>
                                <img src={option.image} className={classes.ListIcon} />
                                {option.title}
                            </div>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <div className="selectedTags">{chosenCategories}</div>
                </Grid>

                <Grid container item xs={12} md={12} spacing={2} className={classes.ButtonGrid}>
                    <Grid item>
                        <Button size="large" variant="contained" disableElevation className={classes.Button}>Get 1 new idea weekly $2 <ArrowRightAltIcon className={classes.Arrow} /></Button>
                    </Grid>
                    <Grid item>
                        <Button size="large" variant="contained" disableElevation className={classes.ButtonSignup}>Get 1 new idea daily $10 <ArrowRightAltIcon className={classes.Arrow} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );

};
export default Subscribe;